<template>
  <div
    v-click-outside="onClickOutside"
    class="c-date-picker"
    :class="classes"
  >
    <div class="border" />

    <div class="value" tabindex="0" @click="opened = !opened">
      <c-flag
        v-if="icon"
        grey
        class="flag"
        size="30"
        icon-size="18"
        :icon="icon"
      />

      <c-flag
        v-if="expiredDate"
        class="expired"
        validation
      />

      <input
        v-if="enableInput"
        ref="input"
        v-mask="'##/##/####'"
        type="text -input"
        :class="inputClasses"
        :value="inputValue"
        :placeholder="placeholder"
        @input.stop="onInput"
        @keyup.enter.stop.prevent
        @keydown.enter.stop.prevent
        @keypress.enter.stop.prevent
      />

      <c-transition v-else mode="out-in" duration="100">
        <span
          :key="displayedValue"
          :class="[ 'text', { '-placeholder': hasPlaceholder, '-expired': this.expiredDate } ]"
        >
          {{ displayedValue }}
        </span>
      </c-transition>

      <c-icon
        class="select-icon"
        icon="arrow-right-2"
        size="24"
      />
    </div>

    <div class="wrapper">
      <transition name="content">
        <div v-if="opened" class="content">
          <c-button
            class="redefine-btn"
            type="button"
            flat
            :size="35"
            @click.stop="onReset"
          >
            Redefinir
          </c-button>

          <c-calendar
            ref="calendar"
            wide
            :panes="1"
            :value="internalValue"
            :mode="mode"
            :max-date="maxDate ? new Date(maxDate) : undefined"
            :min-date="minDate ? new Date(minDate) : undefined"
            :disabled-dates="disabledDates"
            @input="onSelect"
            @preselect="$emit('preselect', $event)"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { maska as mask } from 'maska'
import { is, isEmptyObject } from '@convenia/helpers'
import { tz, dayjs } from '@convenia/utils'
import { parseFromTimeZone, formatToTimeZone } from 'date-fns-timezone'
import { clickOutside } from '@convenia/components/directives'

/**
 * TO DO:
 *
 * - Add support to 'multiple' mode
 */

export default {
  name: 'CDatePicker',

  directives: { clickOutside, mask },

  props: {
    /**
     * The icon to show field.
     */
    icon: {
      type: String,
      default: 'calendar-schedule-2'
    },

    /**
     * The placeholder of the field.
     */
    placeholder: {
      type: String,
      default: 'Selecione a data'
    },

    /**
     * The value of the field.
     *
     * set to an Object with the following format when mode is 'range':
     * @type { { start: Date, end: Date } }
     */
    value: {
      type: [ Object, String, Date ],
      required: true,

      // This default is set just to avoid problems with storybook
      default: () => ({})
    },

    /**
     * The lower limit selectable date.
     */
    minDate: Date,

    /**
     * The upper limit selectable date.
     */
    maxDate: Date,

    /**
     * The date selecting mode
     * The validation array is self explanatory.
     */
    mode: {
      type: String,
      default: 'single',
      validator: mode => [ 'range', 'single' ].includes(mode),
    },

    /**
     * Disables the field
     */
    disabled: Boolean,

    /**
     * Displays a validation error highlight
     */
    error: Boolean,

    /**
     * A date expression of disabled dates in the calendar
     * Check https://vcalendar.io/date-expressions.html
     */
    disabledDates: {
      type: Object,
      default: null,
    },

    /**
     * Makes the input area editable
     */
    enableInput: Boolean,

    /**
     * Displays if the date is expired by displaying an alert icon
     */
    expiredDate: Boolean
  },

  data () {
    return {
      opened: false,
      inputChanged: false,
      inputValue: undefined,
      internalValue: undefined
    }
  },

  watch: {
    opened (val) {
      this.$emit(val ? 'focus' : 'blur')
    },

    value: {
      immediate: true,
      handler: 'setInternalValue'
    },
  },

  computed: {
    classes () {
      return {
        '-opened': this.opened,
        '-disabled': this.disabled,
        '-has-icon': this.icon,
        '-validation': this.error,
        '-expired': this.expiredDate
      }
    },

    inputClasses () {
      return [ 'text', {
        '-placeholder': !this.enableInput && !this.internalValue,
        '-input': this.enableInput,
        '-expired': this.expiredDate
      } ]
    },

    displayedValue () {
      if (!this.internalValue) return this.placeholder
      const isObject = is(this.internalValue, 'Object')
      const hasStart = (this.internalValue || {}).start
      const hasEnd = (this.internalValue || {}).end

      if (this.mode === 'range' && isObject && hasStart && hasEnd) {
        const startDate = this.internalValue.start.toLocaleDateString('pt-BR')
        const endDate = this.internalValue.end.toLocaleDateString('pt-BR')
        return `${startDate} - ${endDate}`
      }

      if (this.mode === 'single' && is(this.internalValue, 'Date'))
        return this.internalValue.toLocaleDateString('pt-BR')

      return this.placeholder
    },

    hasPlaceholder () {
      const { internalValue } = this
      if (is(internalValue, 'Object') && this.mode === 'single')
        return !Object.keys(internalValue).length

      return !this.internalValue
    }
  },

  methods: {
    parseDate (date) {
      const isDate = is(date, 'Date')

      if (tz.guess(date) === 'America/Sao_Paulo') return date

      return isDate
        ? parseFromTimeZone(date.toISOString(), { timeZone: tz.guess() })
        : parseFromTimeZone(date, { timeZone: tz.guess() })
    },

    formatDate (date) {
      return is(date, 'Date')
        ? formatToTimeZone(date.toISOString(), 'DD/MM/YYYY', { timeZone: tz.guess() })
        : formatToTimeZone(date, 'DD/MM/YYYY', { timeZone: tz.guess() })
    },

    // I don't know, it just works, ok?
    normalizeDate (value) {
      if (!value) return null

      return parseFromTimeZone(`${value} 20:00:00`, 'DD/MM/YYYY hh:mm:ss', { timeZone: tz.guess() })
    },

    onSelect (value) {
      if (this.mode === 'range') this.internalValue = {
        start: value.start,
        end: value.end
      }

      if (this.mode === 'single')
        this.internalValue = this.parseDate(value)

      this.$emit('input', this.internalValue)
      this.setInputValue()
      this.opened = false
    },

    setInputValue () {
      if (!this.internalValue) return
      const val = this.formatDate(this.internalValue)

      if (val === this.inputValue || val === 'Invalid Date') return
      this.inputValue = val
    },

    setInternalValue (val) {
      /**
       * For now we are only dealing with the 'range' mode
       * which expects the value to be an object with
       * 'start' and 'end' keys
       */
      if (is(val, 'Object') && val.start && val.end)
        this.internalValue = { ...val }

      if (val && is(val, 'String') && val.match(/[0-9]*-[0-9]*-[0-9]*/))
        this.internalValue = dayjs(val).toDate()

      else
        this.internalValue = val

      if (this.enableInput && this.mode === 'single' && !this.inputChanged)
        this.setInputValue()

      this.inputChanged = false
    },

    isDateValid (dateString) {
      const date = dayjs(this.normalizeDate(dateString))

      if (!dateString || !date.isValid()) return false

      const tooBig = this.maxDate && date > dayjs(this.maxDate)
      const tooSmall = this.minDate && date < dayjs(this.minDate)
      const same = date === dayjs(this.internalValue)

      if (same || tooBig || tooSmall) return false

      return true
    },

    async onInput (ev) {
      const { value = '' } = ev.target || {}

      if (value.length < 10) return

      const [ day, month, year ] = value.split('/')

      let date = this.internalValue && !isEmptyObject(this.internalValue)
        ? dayjs(this.internalValue)
        : dayjs()

      const calendar = this.$refs.calendar || {}
      const { controller } = calendar || {}
      const { input } = this.$refs || {}

      const isDayValid = (day || '').length === 2 && +day > 0 && +day < 32
      const isMonthValid = (month || '').length === 2 && +month > 0 && +month < 13
      const isYearValid = (year || '').length === 4 && +year > 0

      if (isDayValid)
        date = date.set('date', +day)
      if (isMonthValid)
        date = date.set('month', +month - 1)
      if (isYearValid)
        date = date.set('year', +year)

      if (!(value || '').trim() || (!isDayValid && !isMonthValid && !isYearValid))
        return

      this.inputChanged = true
      this.inputValue = value
      this.internalValue = this.parseDate(date.toDate())
      this.$emit('input', this.internalValue)

      if (controller && this.opened) {
        await controller.focusDate(this.internalValue)
        this.$nextTick(() => { input.focus() })
      }
    },

    onReset () {
      const calendar = this.$refs.calendar || {}
      const { controller } = calendar || {}

      this.internalValue = ''
      this.inputValue = ''
      this.$emit('input', undefined)
      if (controller) controller.focusDate(new Date())
    },

    onClickOutside () {
      this.opened = false

      if (!this.inputChanged)
        this.setInternalValue(this.value)

      this.inputChanged = false
    }
  }
}
</script>

<style lang="scss">
.c-date-picker {
  $calendar-max-width: 276px;
  $content-height: 350px;
  $initial-height: 40px;
  $initial-border-radius: 20px;
  $open-border-radius: 5px;

  position: relative;
  overflow: visible;
  z-index: 0;
  user-select: none;

  // margin-bottom transition to support
  // CInputContainer margin-bottom transition
  transition: z-index .3s, margin-bottom .3s;

  &.-disabled {
    user-select: none;
    pointer-events: none;

    & > .value {
      font-size: 14px;
      color: color-var(text, base-30);
      background-color: color-var(text, base-05);

      & > .text { color: unset; }
      & > .c-icon { @include icon-color(text, base-30); }
    }

    & > .border {
      border: unset;
      background-color: unset;
    }
  }

  & > .border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #FFF;
    border-radius: $initial-border-radius;
    border: 1px solid color-var(text, base-10);
    transition: height .3s, box-shadow .3s, border-radius 0.3s;
    z-index: 1;

    height: $initial-height;
  }

  & > .value {
    position: relative;
    border: 1px solid transparent;
    border-radius: $initial-border-radius;
    transition: border-radius 0.3s;
    height: $initial-height;
    display: flex;
    padding-left: 20px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    outline: none;
    z-index: 1;

    & > .flag > .c-icon { position: relative; top: -1px; }

    & > .expired { margin-left: 10px; }

    & > .text {
      @include typo(body-4, base-50);

      &:not(.-input) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.-placeholder, &.-input::placeholder { @include typo(body-1, base-30); }
      &.-placeholder, &.-input::placeholder.-expired { color: color-var(negative); }

      &.-input {
        border: 0;
        outline: 0;
        transition: color 200ms;
        background: transparent;
        width: 100%;

        &:hover, &:focus { color: color-var(text, base-80); }
      }

      &.-expired {
        color: color-var(negative);

        &:hover, &:focus { color: color-var(negative); }
      }
    }

    & > .select-icon {
      margin-left: auto;
      margin-right: 8px;
      transform: rotate(90deg);
      @include icon-color(text, base-50);
      transition: transform .3s, fill .3s;
    }
  }

  & > .wrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0 0 $initial-border-radius $initial-border-radius;
    background: transparent;
    pointer-events: none;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: border-radius 0.3s;

    & > .content {
      position: relative;
      height: $content-height;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .redefine-btn {
        margin: 10px 0 15px;
        width: 120px;
      }

      & > .apply-btn {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    // transitions
    .content-enter-active, .content-leave-active {
      transition:
        transform .3s cubic-bezier(0, .6, .4, 1),
        opacity .1s;
    }
    .content-enter, .content-leave-to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  &.-has-icon {
    & > .value { padding-left: 5px; }
    & > .value > .text { padding-left: 10px; }
  }

  &.-expired {
    & > .value > .text { padding-left: 6px; }
  }

  &.-opened {
    z-index: var(--z-index-1);

    & > .border {
      box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.2);
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.9) 100%);
      height: $content-height + $initial-height;
      border-radius:
        $initial-border-radius $initial-border-radius
        $open-border-radius $open-border-radius;
    }

    & > .wrapper {
      pointer-events: all;
      height: $content-height;
      border-radius: 0 0 $open-border-radius $open-border-radius;
    }

    & > .value {
      border-bottom: 1px solid transparent;
      cursor: default;

      & > .select-icon { transform: rotate(270deg); }
    }
  }

  &:focus-within > .border {
    @include responsive (tablet, desktop) {
      @include hover();

      border-color: rgba(color-var(primary, base-rgb), .35);
    }
  }

  &.-validation:not(.-opened) > .border {
    @include hover(color-var(negative));

    border-color: rgba(color-var(negative, base-rgb), .35);
    box-shadow: 0 0 0 1px rgba(color-var(negative, base-rgb)), 0px 0px 3px 1px rgba(color-var(negative, base-rgb), 0.55);
  }
}
</style>
