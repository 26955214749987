<template>
  <div class="c-actions-button">
    <template v-if="items.length <= +maxButtonCount">
      <c-button
        v-for="item in items"
        data-testid="item"
        :key="item.label"
        v-bind="{
          iconSize,
          flat: true,
          ...buttonProps,
          ...item
        }"
        :size="buttonSize"
        @click.native.stop="$emit(`action:${item.name}`, item.props)"
      />
    </template>

    <c-button
      v-else-if="!!items.length"
      data-testid="dots"
      icon="dots-menu"
      :size="buttonSize"
      :icon-size="iconSize"
      ref="dots"
      v-bind="{
        flat: true,
        ...buttonProps
      }"
      @click.native.stop="showPopover = !showPopover"
    />

    <c-actions-popover
      v-if="showPopover"
      data-testid="popover"
      class="popover"
      element-spacing="5"
      hide-on-scroll
      ignore-target-click
      :items="items"
      :target="$refs.dots.$el"
      @close="showPopover = false"
      v-on="filteredListeners"
    />
  </div>
</template>

<script lang="js">
export default {
  name: 'CActionsButton',

  props: {
    items: {
      type: Array,
      required: true,
      validator: value => value.every(item => item.label && item.icon && item.name),
    },

    maxButtonCount: {
      type: [ String, Number ],
      default: 2,
    },

    buttonSize: {
      type: [ String, Number ],
      default: '40',
    },

    iconSize: {
      type: [ String, Number ],
      default: '20',
    },

    buttonProps: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({ showPopover: false }),

  computed: {
    filteredListeners () {
      const { close, ...listeners } = this.$listeners
      return listeners
    },
  },
}
</script>

<style lang="scss">
.c-actions-button {
  display: flex;
  flex-flow: row nowrap;

  &:not(:has(.popover)) { gap: 10px; }
}
</style>
