<template>
  <div class="c-condensed-names">
    <c-typo v-bind="$attrs">
      {{ displayedNames }}
    </c-typo>

    <c-typo
      v-if="remainingCount > 0"
      v-tooltip.dark="{ value: remainingNames }"
      class="underline-dashed"
      opacity="base-50"
      weight="semibold"
    >
      +{{ remainingCount }}
    </c-typo>
  </div>
</template>

<script>
export default {
  name: 'CCondensedNames',

  props: {
    names: {
      type: Array,
      required: true
    },

    splitValue: {
      type: Number,
      required: true
    }
  },

  computed: {
    displayedNames () {
      return this.names.slice(0, this.splitValue).join(', ')
    },
    splitedNames () {
      return this.names.slice(this.splitValue)
    },
    remainingNames () {
      return this.splitedNames.join(', ')
    },
    remainingCount () {
      return this.splitedNames.length
    }
  }
}
</script>

<style lang="scss">
.c-condensed-names {
  & > .c-typo.underline-dashed {
    cursor: default;
    border-bottom: 1px dashed color-var(text, base-50);
  }
}
</style>
