<template>
  <div class="c-multiple-avatars">
    <c-avatar
      v-for="user in someAvatars"
      data-testid="avatar-user"
      :key="user.id"
      :src="user.src"
      :name-placeholder="user.namePlaceholder"
      :hide-tooltip="hideTooltip"
      class="-custom-border"
      v-bind="$attrs"
    />

    <c-avatar
      v-if="showResume"
      data-testid="resume-section"
      v-tooltip.dark="{ value: resumeAvatars.tooltip, hide: hideTooltip }"
      :name-placeholder="resumeAvatars.namePlaceholder"
      hide-tooltip
      class="-custom-border"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
export default {
  name: 'CMultipleAvatars',

  props: {
    usersAvatar: {
      type: Array,
      default: () => ([]),
      required: true
    },

    /**
     * Number of avatars that will be visible
     */
    quantity: {
      type: Number,
      default: 3
    },

    /**
     * Number of avatars to show the resume section
     */
    numbersOfParticipantsToShowSummarySection: {
      type: Number,
      default: 3
    },

    hideTooltip: Boolean
  },

  computed: {
    someAvatars () {
      const { usersAvatar } = this
      return usersAvatar.slice(0, this.quantity)
    },

    resumeAvatars () {
      const { usersAvatar } = this
      const resume = usersAvatar.slice(this.quantity)
      const concatenatedNames = resume.map(avatar => avatar.namePlaceholder).join(', ')
      const sizeAvatar = resume.length
      return {
        tooltip: concatenatedNames,
        namePlaceholder: `+ ${sizeAvatar}`
      }
    },

    showResume () {
      return this.usersAvatar.length > this.numbersOfParticipantsToShowSummarySection
    }
  }
}
</script>

<style lang="scss">
.c-multiple-avatars {
  display: flex;
  align-items: center;

  & > .c-avatar.-custom-border {
    box-shadow: 0 0 0 2px #ffffff;
  }

  & > .c-avatar + .c-avatar {
    margin-left: -15px;
  }
}
</style>
