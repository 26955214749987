<template>
  <div :class="classes">
    <section class="input">
      <c-input
        text-area
        :placeholder="placeholder"
        :rows="rows"
        v-bind="$attrs"
        v-on="$listeners"
        @keydown.enter="handleEnter"
      />

      <div v-if="hasFiles" class="files">
        <c-info-tag
          v-for="(file, index) in files"
          :key="file.id"
          class="file"
          removable
          icon="clip-attachment"
          icon-size="18"
          icon-gap="2"
          @remove="$emit('attachment:remove', { ...file, index })"
        >
          {{ file.name }}
        </c-info-tag>
      </div>
    </section>

    <c-button
      flat
      class="action attachment"
      icon="clip-attachment"
      :disabled="disabledAttachment"
      @click="openAttachmentInput"
    />

    <c-button
      class="action submit"
      icon="send-message"
      :disabled="disabledSubmit"
      :loading="loading"
      @click="submit"
    />

    <input
      style="display: none"
      ref="attachmentInput"
      type="file"
      :accept="extensions"
      :multiple="multiple"
      @change.prevent="onAddAttachments"
    >
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CChatInput',

  mixins: [ MediaQuery ],

  props: {
    /**
     * Input's placeholder
     */
    placeholder: {
      type: String,
      default: 'Digite sua mensagem',
    },

    /**
     * Accepted file extensions
     *
     * @type {string[]}
     */
    acceptedFileExtensions: {
      type: Array,
      default: () => [ '*' ],
    },

    /**
     * Whether the input should accept multiple files
     */
    multiple: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the attachment button is disabled
     */
    disabledAttachment: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the submit button is disabled
     */
    disabledSubmit: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the submit button is loading
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * An array of files to be added to the conversation.
     *
     * @typedef {Object} Item
     * @property {string} id - The unique identifier for the item.
     * @property {string} name - The name of the item.
     *
     * @type {Item[]}
    */
    files: {
      type: Array,
      default: () => [],
    },
  },

  emits: [
    /**
     * Emits the value when the users inputs it on the input
     */
    'input',

    /**
     * Emits the input value when the user presses the enter key
     * or clicks the submit button
     */
    'submit',

    /**
     * Emits the files when the user adds attachments
     */
    'attachments:add',

    /**
     * Emits the file to be removed
     */
    'attachment:remove',
  ],

  computed: {
    extensions () {
      return this.acceptedFileExtensions.join(',')
    },

    rows () {
      return this.isMobile ? 1 : 3
    },

    hasFiles () {
      return !!this.files?.length
    },

    classes () {
      return [ 'c-chat-input', {
        '-has-files': this.hasFiles,
      } ]
    },
  },

  methods: {
    openAttachmentInput () {
      this.$refs.attachmentInput.click()
    },

    submit () {
      this.$emit('submit', this.$attrs.value)
    },

    handleEnter (event) {
      if (event?.shiftKey) return

      event?.preventDefault()
      this.submit()
    },

    onAddAttachments (event) {
      const { files } = event?.target || {}

      this.$emit('attachments:add', files || [])
    },
  },
}
</script>

<style lang="scss">
.c-chat-input {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;

  & > .input {
    flex: 1 1;
    position: relative;

    & > .files {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      overflow-y: auto;
      padding-left: 10px;

      & > .file {
        margin-top: 5px;
        margin-right: 10px;
        max-width: calc(100% - 10px);
      }
    }
  }

  & > .action { flex: 0 0; }

  @include media-after(map-get($breakpoints, tablet)) {
    & > .action.attachment { order: -1; }
  }

  &.-has-files {
    & > .input textarea { padding-bottom: 50px; }

    @include media-after(map-get($breakpoints, tablet)) {
      & > .action { margin-bottom: 40px; }
    }

  }
}
</style>
