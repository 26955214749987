<template>
  <transition name="c-actions-popover-slide" appear>
    <div
      v-click-outside="{ ev: 'mouseup', fn: onClickOutside }"
      class="c-actions-popover"
    >
      <c-popover
        align="left"
        class="popover"
        :element-spacing="elementSpacing"
        :selector="scrollSelector"
        :target="target"
        :scroll-target="scrollTarget"
        :position="clickPosition ? calculatedPosition : position"
        v-bind="$attrs"
        @handler="$emit('close')"
        v-on="$listeners"
      >
        <div
          :class="[ 'arrow', { '-hover': !!arrowHover }, calculatedPosition, arrowColor ]"
        />

        <c-card class="actions" no-border no-hover>
          <slot>
            <div
              v-for="(item, index) in items"
              :key="index"
              :ref-cy="`logged-action-${item.key}`"
              :data-testid="`action-${item.name || item.label}`"
              :class="getClasses(item, index)"
              @click.prevent.stop="action(item)"
              @mouseover="setArrow(index)"
              @mouseout="arrowHover = false"
            >
              <c-icon
                v-if="item.icon"
                class="icon"
                :size="20"
                :icon="item.icon"
              />

              <span
                class="text"
                :ref-cy="'popover-' + item.label"
              >
                {{ item.label }}
              </span>

            </div>
          </slot>
        </c-card>
      </c-popover>

      <c-overlay
        class="overlay"

        :show="isMobile"
        :transparent="!isMobile"

        @close.prevent.stop="e => $emit('close', e)"
      />
    </div>
  </transition>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import { clickOutside } from '@convenia/components/directives'

export default {
  name: 'CActionsPopover',

  mixins: [ MediaQuery ],

  directives: { clickOutside },

  props: {
    target: {
      type: [ Object, Element ],
      required: true
    },
    scrollSelector: {
      type: String,
      default: 'body'
    },
    scrollTarget: {
      type: [ Object, Element ],
      default: () => {},
    },
    elementSpacing: {
      type: [ String, Number ],
      default: 5
    },
    items: {
      type: Array,
      default: () => [],
    },
    clickPosition: {
      type: Number,
      default: null,
    },
    height: {
      type: [ String, Number ],
      default: 0,
    },
    position: {
      type: String,
      default: 'bottom'
    },
    ignoreTargetClick: Boolean
  },

  data: () => ({ arrowHover: false, arrowColor: 'red' }),

  methods: {
    action ({ action, name, props }) {
      typeof action === 'function' && action()
      name && this.$emit(`action:${name}`, props)
      this.$emit('close')
    },

    getClasses (item, index) {
      return [ 'action', {
        [`-border-${item.border}`]: item.border,
        [`-highlight-${item.highlight}`]: item.highlight,
        '-first': this.calculatedPosition === 'bottom' && index === 0,
        '-last': this.calculatedPosition === 'top' && index === this.items.length - 1
      } ]
    },

    setArrow (index) {
      const isFirst = index === 0 && this.calculatedPosition === 'bottom'
      const isLast = index === this.items.length - 1 && this.calculatedPosition === 'top'
      if (isFirst) {
        this.arrowHover = true
        this.arrowColor = this.items[0].highlight
      } else if (isLast) {
        this.arrowHover = true
        this.arrowColor = this.items[this.items.length - 1].highlight
      }
    },

    onClickOutside (e) {
      const target = (this.target || {}).$el || this.target

      if (this.ignoreTargetClick && target.contains(e.target)) return
      this.$emit('close')
    }
  },

  computed: {
    calculatedPosition () {
      if (!this.clickPosition || window.innerHeight - this.clickPosition > parseInt(this.height)) return 'bottom'
      return 'top'
    }
  }
}
</script>

<style lang="scss">
$border-color-hover: rgb(250, 250, 251);

%hover-arrow {
  z-index: $z-index-4;
  border-style: solid;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;

  transition: border-color .15s, background-color .15s;
}

.c-actions-popover {
  & > .overlay {
    display: unset;
    cursor: default;
  }

  @include responsive (xs-mobile, mobile) {
    position: fixed;
    width: 100vw;
    height: 100vh;

    z-index: var(--z-index-5);
  }

  & > .c-popover.popover {
    @include responsive (xs-mobile, mobile) {
      bottom: 10px;
      z-index: var(--z-index-4);
      left: 50% !important;
      top: unset !important;
      transform: translateX(-50%);

      width: calc(100vw - 20px);

      &::before { display: none; }
    }

    &::before {
      box-shadow: none;
      display: none;
    }

    & > .arrow {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 4px 0 4px 0;
      background-color: #FFFFFF;
      transform: rotate(45deg);
      right: 15px;
      border-width: 5px;

      &.bottom { top: -5px }
      &.top { bottom: -5px }

      @include responsive (xs-mobile, mobile) { display: none; }

      &.-hover {
        background-color: $border-color-hover;
        &.red { background-color: rgba(255, 121, 135, 0.2); }
      }
    }

    & > .actions {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      min-width: 240px;

      overflow: hidden;

      background-color: transparent !important;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.95) 100%) !important;
      box-shadow: unset;

      & > .action {
        display: flex;
        flex-basis: 50%;
        align-items: center;

        cursor: pointer;
        padding: 10px;
        border-bottom: none;
        background-color: transparent;
        color: color-var(text, base-80);

        transition: background-color .15s;

        &::before { @extend %hover-arrow; }
        &:hover { background-color: color-var(text, base-02); }
        &:only-child { border-radius: 5px; }

        @include responsive (xs-mobile, mobile) { &::before { display: none; } }

        &.-first {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          &::before { top: -5px; }
        }

        &.-last {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          &::before { bottom: -5px; }
        }

        &.-last:hover::before {
          border-color: color-var(text, base-02);
          background-color: color-var(text, base-02);

          border-bottom-color: $border-color-hover;
          border-right-color: $border-color-hover;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &.-highlight-red { color: color-var(negative); }
        &.-highlight-red > .icon { @include icon-color(negative); }
        &.-highlight-red:hover {
          background: rgba(255, 121, 135, 0.2);

          &.-first::before {
           border-color: rgba_to_rgb(rgba(255, 121, 135, 0.2));
          }
        }

        &.-border-top { border-top: var(--base-border) }
        &.-border-bottom { border-bottom: var(--base-border) }

        & > .text { padding-left: 10px; font-size: 14px; }
        & > .icon { @include icon-color(text, base-30) }

        @include responsive (xs-mobile, mobile) {
          padding: 20px;

          & > .text { padding-left: 20px }
        }
      }
    }
  }
}

@include responsive (xs-mobile, mobile) {
  .c-actions-popover-slide {
    &-enter-active,
    &-leave-active {
      transition: opacity .5s;

      & > .popover { transition: bottom .4s; }
    }

    &-enter,
    &-leave-to {
      opacity: 0;

      & > .popover { bottom: -100% !important; }
    }
  }
}
</style>
