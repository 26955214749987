<template>
  <div :class="classes">
    <c-avatar
      class="avatar"
      hide-tooltip
      :src="userImage"
      :name-placeholder="userInitials"
    />

    <section class="content">
      <header class="name">
        <c-typo
          type="h3"
          opacity="base-80"
          weight="medium"
          color="text"
        >{{ userName }}</c-typo>
      </header>

      <p class="message">
        <c-typo
          type="body-1"
          opacity="base-60"
          color="text"
        >{{ formattedMessage }}</c-typo>
      </p>

      <footer class="footer">
        <section class="files" v-if="(files || []).length">
          <c-info-tag
            v-for="(file, i) in files"
            :key="i"
            class="file"
            icon="clip-attachment"
            icon-size="18"
            icon-gap="2"
            @click.native="onFileClick(file)"
          >
            {{ file.name }}
          </c-info-tag>
        </section>

        <section class="information">
          <c-icon
            v-if="!isRemovedUser && isDelivered"
            class="delivered"
            size="15"
            icon="done-check-3"
          />

          <c-typo
            class="time"
            type="body-2"
            opacity="base-30"
            color="text"
          >{{ formattedTime }}</c-typo>
        </section>
      </footer>
    </section>
  </div>
</template>

<script>
import { dayjs } from '@convenia/utils'

export default {
  name: 'CChatBubble',

  props: {
    /**
     * An object representing the user details.
     * @type {Object}
     * @property {string} name - The name of the user.
     * @property {string} image - The URL of the user's image.
     */
    user: {
      type: Object,
      default: () => ({}),
    },

    /**
     * The message to be displayed.
     * @type {string}
     */
    message: {
      type: String,
      default: '',
    },

    /**
     * The time the message was sent.
     * @type {string|Date|number}
     */
    time: {
      type: [ String, Date, Number ],
      required: true,
    },

    /**
     * An array of files to be displayed.
     *
     * @typedef {Object} Item
     * @property {string} id - The unique identifier for the item.
     * @property {string} name - The name of the item.
     *
     * @type {Item[]}
     */
    files: {
      type: Array,
      default: () => [],
    },

    /**
     * A flag to indicate if the message was sent by the current user.
     * @type {boolean}
     */
    isCurrentUser: {
      type: Boolean,
      default: false,
    },

    /**
     * A flag to indicate if the user was removed.
     * @type {boolean}
     */
    isRemovedUser: {
      type: Boolean,
      default: false,
    },

    /**
     * A flag to indicate if the message was delivered.
     * @type {boolean}
     */
    isDelivered: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    /**
     * Emits when a file is clicked.
     */
    'click:file'
  ],

  computed: {
    classes () {
      return [ 'c-chat-bubble', {
        '-current-user': this.isCurrentUser,
      } ]
    },

    userName () {
      return this.isRemovedUser
        ? 'Usuário excluído'
        : this.user?.name || ''
    },

    formattedMessage () {
      return this.isRemovedUser ? '-' : this.message
    },

    userInitials () {
      return this.isRemovedUser ? '' : this.userName
    },

    userImage () {
      return this.isRemovedUser ? null : this.user?.image
    },

    formattedTime () {
      return dayjs(this.time).format('HH:mm')
    },
  },

  methods: {
    onFileClick (file) {
      this.$emit('click:file', file)
    },
  },
}
</script>

<style lang="scss">
.c-chat-bubble {
  --c-chat-bubble-avatar-margin-top: 16px;
  --c-chat-bubble-arrow-size: 13px;
  --c-chat-bubble-displacement: 5px;
  --c-chat-bubble-arrow-border-radius: 3px;

  %chat-bubble-arrow-common {
    content: '';
    position: absolute;
    top: calc(var(--c-chat-bubble-avatar-margin-top) + 15px);
    transform: rotate(45deg);
    width: var(--c-chat-bubble-arrow-size);
    height: var(--c-chat-bubble-arrow-size);
    background-color: inherit;
  }

  display: flex;
  align-items: flex-start;
  gap: 20px;

  & > .avatar {
    margin-top: var(--c-chat-bubble-avatar-margin-top);
  }

  & > .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: var(--base-border-radius);
    width: 100%;
    max-width: calc(100% - 60px);
    position: relative;

    @include responsive(tablet, desktop) { padding-right: 60px; }

    & > .name {
      font-weight: bold;
      margin-bottom: 5px;
    }

    & > .message {
      white-space: pre-line;
      text-wrap: balance;
      margin-bottom: 15px;
    }

    & > .footer {
      display: flex;
      align-items: center;
      max-width: 100%;

      & > .files {
        flex-flow: row wrap;
        display: flex;
        gap: 10px;
        margin-top: 5px;
        max-width: inherit;

        @include responsive(xs-mobile, tablet) { margin-bottom: 15px; }

        & > .file {
          cursor: pointer;
          max-width: inherit;
        }
      }

      & > .information {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        right: 15px;

        & > .delivered {
          margin-right: 5px;
          stroke: color-var(info, base-70);
        }

        & > .time { color: color-var(text, base-40); }
      }
    }
  }

  &:not(.-current-user) {
    & > .content {
      background-color: rgba-to-rgb(map-get($text-color, base-02));

      &::before {
        @extend %chat-bubble-arrow-common;
        left: calc(-1 * var(--c-chat-bubble-displacement));
        border-radius: 0 0 0 var(--c-chat-bubble-arrow-border-radius);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  &.-current-user {
    flex-direction: row-reverse;

    & > .content {
      background-color: color-var(positive, lighter);

      &::after {
        @extend %chat-bubble-arrow-common;
        right: calc(-1 * var(--c-chat-bubble-displacement));
        border-radius: 0 var(--c-chat-bubble-arrow-border-radius) 0 0;
        clip-path: polygon(100% 100%, 0% 0%, 100% 0%);
      }
    }
  }
}
</style>
