<script>
import {
  TYPE_OPTIONS,
  OPACITY_OPTIONS,
  WEIGHT_OPTIONS,
  COLOR_OPTIONS
} from './consts'

export default {
  name: 'CTypo',

  functional: true,

  props: {
    /**
     * Defines the font-family and font-size css values
     */
    type: {
      type: String,
      default: 'body-1',
      validator: (value) => TYPE_OPTIONS.includes(value)
    },

    /**
     * Defines the opacity, be aware that not all combinations with color
     * works. Check the $color-palletes rcss variable to check all valid combinations
     */
    opacity: {
      type: String,
      default: 'base',
      validator: (value) => OPACITY_OPTIONS.includes(value)
    },

    /**
     * Defines the font-style italic css property
     */
    italic: {
      type: Boolean,
    },

    /**
     * Defines the text-decoration line-through css property
     */
    strikethrough: {
      type: Boolean,
    },

    /**
     * Defines the font-weight css property
     */
    weight: {
      type: String,
      default: 'regular',
      validator: (value) => WEIGHT_OPTIONS.includes(value)
    },

    /**
     * Defines the color, be aware that not all combinations with opacity
     * works. Check the $color-palletes rcss variable to check all valid combinations
     */
    color: {
      type: String,
      default: 'neutral',
      validator: (value) => COLOR_OPTIONS.includes(value)
    }
  },

  render (h, { props, slots, data }) {
    const staticClasses = data?.staticClass?.split(' ')

    const classes = [
      'c-typo',
      `-type-${props.type}`,
      {
        '-italic': props.italic,
        '-strikethrough': props.strikethrough
      },
      ...(staticClasses || [])
    ]

    const style = {
      '--typo-color': `var(--color-${props.color}-${props.opacity})`,
      '--typo-weight': `var(--font-weight-${props.weight})`
    }

    return h(
      'span',
      {
        class: classes,
        style,
        directives: data.directives,
      },
      slots().default
    )
  }
}
</script>

<style lang="scss">
.c-typo {
  font-weight: var(--typo-weight);
  color: var(--typo-color);

  &.-type-body-1 {
    font-family: var(--base-font-family); // 'Nunito Sans'
    font-size: 14px;
  }

  &.-type-body-2 {
    font-family: var(--base-font-family); // 'Nunito Sans'
    font-size: 12px;
  }

  &.-type-display-1 {
    font-family: var(--base-font-family); // 'Nunito Sans'
    font-size: 32px;
  }

  &.-type-display-2 {
    font-family: var(--base-font-family); // 'Nunito Sans'
    font-size: 16px;
  }

  &.-type-h1 {
    font-family: var(--title-font-family); // 'Ubuntu'
    font-size: 22px;
  }

  &.-type-h2 {
    font-family: var(--title-font-family); // 'Ubuntu'
    font-size: var(--title-font-size); // 16px
  }

  &.-type-h3 {
    font-family: var(--title-font-family); // 'Ubuntu'
    font-size: 14px;
  }

  &.-type-h4 {
    font-family: var(--title-font-family); // 'Ubuntu'
    font-size: 11px;
    text-transform: uppercase;
  }

  &.-italic {
    font-style: italic;
  }

  &.-strikethrough {
    text-decoration: line-through;
  }
}
</style>
