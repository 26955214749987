<template>
  <div :class="[ 'c-editable-cell', { '-loading': loading } ]">
    <c-loader
      v-if="loading"
      :size="loaderSize"
      data-testid="loader"
    />

    <template v-else>
      <c-table-input
        v-tooltip.dark="{
          value: tooltipValue,
          hide: !showTooltip
        }"
        ref="input"
        data-testid="input"
        no-auto-focus
        size="30px"
        class="value"
        :value="value"
        :mask="mask"
        :is-money="isMoney"
        :disabled="disabled"
        :placeholder="placeholder"
        @lost-focus="onUpdate"
        v-on="$listeners"
      />

      <c-icon
        v-if="!disabled"
        class="icon"
        icon="pen-edit-17"
        size="10"
        data-testid="icon"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'CEditableCell',

  props: {
    value: {
      type: String,
      required: true
    },
    mask: {
      type: [ String, Array ],
      default: '',
    },
    loading: Boolean,
    fontSize: {
      type: [ Number, String ],
      default: 11
    },
    lengthLimit: {
      type: Number,
      default: 0,
    },
    tooltipValue: {
      type: String,
      default: '',
    },
    showTooltip: Boolean,
    isMoney: Boolean,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    loaderSize: {
      type: String,
      default: '30'
    }
  },

  emits: [ 'update' ],

  methods: {
    onUpdate (value) {
      if (this.lengthLimit && (value || '').length > this.lengthLimit) {
        const trimmedValue = value.slice(0, this.lengthLimit)
        const el = this.$refs.input.$el || this.$refs.input

        el.value = trimmedValue
        this.$emit('update', trimmedValue)
        return
      }

      this.$emit('update', value)
    }
  }
}
</script>

<style lang="scss">
.c-editable-cell {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: text;
  color: color-var(text, base-80);

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 2px;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-bottom: 1px dashed color-var(text, base-30);
  }

  &.-loading {
    cursor: default;

    &::before { border-bottom: none; }
  }

  & > .value {
    outline: 0;
    min-width: 20px;
  }

  @include responsive (tablet, desktop) {
    &:hover:not(:focus-within) > .icon { visibility: visible }
  }

  & > .icon {
    position: absolute;
    top: 50%;
    right: -20px;
    margin: 0;

    transform: translateY(-50%);
    @include icon-color(text, base-30);

    @include responsive (tablet, desktop) {
      visibility: hidden;
    }
  }
}
</style>
