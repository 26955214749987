<template>
  <div class="c-radio-custom" :class="classes" @click="emitInput(option.value)">
    <div class="inner">
      <c-flag
        v-if="option.icon"
        class="icon"
        :icon="option.icon"
        :theme="option.theme"
        grey
      />

      <c-avatar
        v-else
        class="avatar"
        :placeholder="option.namePlaceholder"
        :name-placeholder="option.namePlaceholder"
        hide-tooltip
      />

      <div class="infos">
        <span class="info-name">{{ option.name }}</span>

        <span class="info-description">{{ option.description }}</span>
      </div>
    </div>

    <div class="native">
      <input
        type="radio"
        :disabled="disabled || option.disabled"
        :value="option.value"
        :checked="isSelectedNative(option.value)"
        @click="emitInput(option.value)"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CRadioCustom',

  props: {
    option: {
      type: Object,
      default: () => ({}),
    },

    value: {
      type: [ Object, String, Boolean, Number ],
      default: ''
    },

    dashed: {
      type: Boolean,
      default: true
    },

    disabled: Boolean,
  },

  computed: {
    classes () {
      return {
        '-dashed': this.dashed,
        '-disabled': this.disabled,
      }
    }
  },

  methods: {
    isSelected (option) {
      return this.value === option
        ? 'selected'
        : 'unselected'
    },

    isSelectedNative (option) {
      return this.isSelected(option) === 'selected'
    },

    emitInput (option) {
      this.$emit('input', option)
    }
  }
}
</script>

<style lang="scss">
.c-radio-custom {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  margin-bottom: 10px;
  cursor: pointer;

  border: 1px solid rgba(18,30,72,0.1);
  border-radius: 5px;
  background-color: transparent;
  transition: background-color .2s ease-out;

  &:hover {
    background-color: rgba(18,30,72,0.02);
  }

  & > .inner {
    display: flex;

    & > .infos {
      display: flex;
      align-self: center;
      flex-direction: column;
      padding-left: 10px;

      & > .info-name {
        line-height: 19px;
        padding-bottom: 2px;
        @include typo(h2);
      }

      & > .info-description {
        line-height: 16px;
        color: color-var(text, base-50);
        font-size: 14px;
      }
    }
  }

  & > .native {
    display: flex;
    padding-left: 24px;
    align-items: center;
    align-self: center;
    cursor: pointer;
  }

  & > .native input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 25px;
    height: 25px;
    border: 1px solid rgba(18,30,72,0.1);
    border-radius: 50%;
    background-color: #fff;
    outline: none;
    transition: border-color 0.3s ease;
  }

  & .native input[type="radio"]:checked {
    border-color: #63E1A5;
    background-color: #63E1A5;
    box-shadow: 0 2px 10px rgba(#63E1A5, 0.5);
  }

  & > .native input[type="radio"]:checked::before {
    content: "";
    display: inline-block;
    transform: rotate(45deg);
    height: 10px;
    width: 5px;
    margin-left: 9px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  &.-dashed {
    border: 1px dashed rgba(18,30,72,0.2);
  }

  &.-disabled {
    pointer-events: none;

    > .native input[type="radio"] {
      background-color: color-var(text, base-10);
      border-color: transparent;
      box-shadow: none;
    }
  }
}
</style>
