<template>
  <div :class="classes">
    <div
      v-for="field in visibleFields"
      :key="field.name"
      class="info"
      :field-name="field.name"
      :style="style"
    >
      <div :class="getLabelClasses(field)">{{ sanitize(field.label) }}</div>

      <!-- TODO: put this as a value slot on SPA -->
      <div v-if="field.type === 'multi-check'" class="multi-check">
        <span v-if="!(field.value || []).length" class="fallback">-</span>

        <c-label
          v-else
          v-for="value in field.value"
          :key="value.label"
          thin
          :primary="!value.labelProps || isEmptyObject(value.labelProps)"
          class="label"
          v-bind="value.labelProps || {}"
        >
          {{ value.label }}
        </c-label>
      </div>

      <span
        v-else
        :ref-cy="getRefCy(field.label)"
        :class="[ 'value', { '-email': field.label === 'E-mail' } ]"
      >
        <template v-if="isDate(field)">
          {{ formatDate(field) }}
        </template>

        <slot v-else :field="field" :name="field.type">
          <span
            v-if="field.type === 'color'"
            class="color"
            :style="{ '--color': field.value }"
          />
          {{ loading ? '-' : getFieldValue(field) || '-' }}
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
import * as helpers from '@convenia/helpers'

const { is, normalizeDiacritics, isEmptyObject, formatDateBR } = helpers

export default {
  name: 'CInfo',

  props: {
    /**
     * An object/array of fields with the following shape:
     *
     * { label: The label to be displayed for the field.
     * , value: The actual value of the field.
     * , name: The name of the field, this is kind of optional.
     * }
     */
    fields: {
      type: [ Array, Object ],
      required: true
    },

    /**
     * Wheter it's contents are loading or not.
     */
    loading: Boolean,

    /**
     * card gap to form
     */
    margin: {
      type: [ String, Number ],
      default: 230
    },

    /**
     * A string used to identify the set of fields
     * displayed in the component, used for global search
     * porpuses
     */
    infoKey: {
      type: String,
      default: ''
    },

    /**
     * option to capitalize the texts of the card
     */
    capitalize: {
      type: Boolean,
      default: false
    },

    highlightedField: {
      type: Object,
      default: () => ({})
    },

    /**
     * Displays the info horizontally
     */
    horizontal: Boolean,

    labelLeft: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    isEmptyObject,
    sanitize (string) {
      return (string || '')
        .replace(/<\/?[^>]+(>|$)/g, '')
        .trim()
    },
    formatToBRL (value) {
      return value
        ? 'R$ ' + (
          Number(value)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
        : '-'
    },

    getFieldValue (field) {
      if (is(field.value, 'Function')) return field.value(this.fields)

      if (Array.isArray(field.value)) {
        const values = (field.value || [])
          .map(v => field.displayBy && v ? v[field.displayBy] : v)

        return values.join(', ')
      }

      if (typeof field.value === 'object' && (!field.value || field.value.value === null)) return '-'

      if (typeof field.value === 'boolean') return field.value ? 'Sim' : 'Não'

      if (field.displayBy) {
        const option = (field.options || []).find(option => option[field.trackBy] === field.value
        )

        if (option) return option[field.displayBy]

        return (field.value || {})[field.displayBy] || field.value
      }

      if (field.isMoney) return this.formatToBRL(field.value)

      return field.value
    },

    getRefCy (label) {
      const id = Array.isArray(this.fields)
        ? (this.fields.find(field => field.label === 'Id') || {}).value
        : this.fields.id && this.fields.id.value

      return `${label}-${id}`
    },

    getLabelClasses ({ label }) {
      const { normalized, infoKey } = this.highlightedField || {}
      const formattedLabel = normalizeDiacritics(label)
      const labelMatches = normalized === formattedLabel
      const highlight = infoKey ? labelMatches && (infoKey === this.infoKey) : labelMatches

      const searchClasses = [
        `--label-${formattedLabel}`,
        { '--search-result': highlight }
      ]

      return [ 'label', ...searchClasses ]
    },

    isDate (field) {
      return field.type === 'date'
        && (is(field.value, 'Date')
        || field.value?.match(/[0-9]*-[0-9]*-[0-9]*/))
    },

    formatDate (field) {
      return formatDateBR(field?.value) || '-'
    }
  },

  computed: {
    visibleFields () {
      return Object
        .entries(this.fields || {})
        .filter(([ , field ]) => {
          const { hide } = (field.infoCardProps || {})
          if (typeof hide === 'function') return !hide()
          return !(field.hide || hide)
        })
        .map(([ key, field ]) => ({
          ...field,
          name: field.name || key
        }))
    },

    classes () {
      return [ 'c-info', {
        '-horizontal': this.horizontal,
        '-label-left': !this.labelLeft
      } ]
    },

    style () {
      return {
        '--label-width': `${parseInt(this.margin) - 30}px`,
        '--capitalize': this.capitalize ? 'capitalize' : 'initial'
      }
    }
  }
}
</script>

<style lang="scss">
.c-info {
  display: grid;
  grid-gap: 20px;

  &.-horizontal {
    grid-auto-flow: column;
  }

  &.-label-left {
    & > .info {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 12px 1fr;
      row-gap: 10px;

      & > .label {
        justify-self: start;
        text-align: left;
        margin-bottom: 0;
      }

      & > .value {
        justify-self: start;
        text-align: left;
        width: 100%;
      }
    }
  }

  & > .info {
    min-width: 0;

    & > .label {
      @include typo(h5, base-50);
      word-break: break-word;
    }

    & > .multi-check {
      @include responsive (xs-mobile, mobile) { padding-top: 5px; }

      margin: -10px 50px 0 -5px;

      & > .fallback { padding-left: 5px; }
      & > .label { margin: 10px 5px 0; }
    }

    & > .value {
      font-size: 14px;
      min-width: 0;
      overflow-wrap: break-word;
      word-wrap: break-word;
      text-transform: var(--capitalize);
      color: color-var(text, base-80);
      line-height: 15px;

      &.-email { text-transform: lowercase; }

      & > .color {
        width: 10px;
        height: 10px;
        display: inline-block;
        position: relative;
        margin-left: 5px;
        margin-right: 8px;
        &:before {
          content: "";
          position: absolute;
          background: var(--color);
          top: -5px;
          right: -5px;
          bottom: -5px;
          left: -5px;
          border-radius: 3px;
        }

        @include responsive (xs-mobile, mobile) {
          margin-left: 2px;
          margin-right: 5px;
          &:before {
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
          }
        }
      }
    }
  }

  @include responsive (tablet, desktop) {
    padding-left: 0;

    & > .info {
      display: grid;
      align-items: baseline;
      grid-column-gap: 30px;
      grid-template-rows: 1fr;
      grid-template-columns: var(--label-width) auto;

      & > .label {
        justify-self: end;
        text-align: right;
      }
    }

    &.-horizontal > .info {
      grid-row-gap: 5px;
      grid-template-columns: var(--label-width);

      & > .label {
        justify-self: initial;
        text-align: initial;
      }
    }
  }

  & > .info {
    & > .label {
      position: relative;
      z-index: 1;
    }

    & > .--search-result {
      &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -4px;
        right: -4px;
        bottom: -2px;
        border-radius: 4px;
        z-index: -1;
        background: rgba(color-var(alert, base-rgb), .2);
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .info {
      display: block;
      & > .label {
        margin-bottom: 5px;
        display: inline-block;
      }
      & > .value {
        display: block;
      }
    }
  }
}
</style>
