<template>
  <label :class="classes" :style="styles">
    <!-- The content (usually just text) of the label. -->
    <slot />
  </label>
</template>

<script>
import { HextoRGB, is } from '@convenia/helpers'
import { theme } from '@convenia/mixins'
/**
 * A tag-like label component.
 */
export default {
  name: 'CLabel',

  mixins: [ theme ],

  props: {
    /**
     * The color of the background, overrides the success and error props.
     */
    backgroundColor: {
      type: [ String, Array ],
      default: null,
    },

    /**
     * The color of the text in the label.
     */
    textColor: {
      type: String,
      default: '',
    },

    /**
     * Smaller horizontal size
     */
    thin: Boolean,

    /**
     * Larger horizontal size
     */
    thick: Boolean,

    /**
     * With the use of mixin for themes it is no longer necessary
     * to use these color props, they can be deleted in the future
     */

    /**
     * Props that will no longer be used
     *
     *
     * @deprecated Please use `positive` instead.
     * Uses our success color for the background.
     */
    success: Boolean,

    /**
     * @deprecated Please use `negative` instead.
     * Uses our error color for the background.
     */
    error: Boolean,

    /**
     * @deprecated Please use `complementary` instead.
     * Uses our alert color for the background, dark variant.
     */
    alertDark: Boolean,

    /**
     * @deprecated Please use `support` instead.
     * Uses our info color for the background.
     */
    info: Boolean,

    /**
     * @deprecated Please use `disabled` instead.
     * Applies a muted appearance (grey bg with no shadows) to
     * the component
     */
    muted: Boolean,

    primary: Boolean,

    alert: Boolean,

    canceled: Boolean,

    /**
     * Fixed width
     */
    width: {
      type: [ String, Number ],
      default: null,
    },

    /**
     * @deprecated This prop is no longer needed.
     * Label's alternative style, simply changes certain aspects
     * of the label slightly depending on the other props
     */
    alternative: Boolean,

    complementary: Boolean
  },

  computed: {
    classes () {
      /**
       * Remove else when all old code is refactored
       */
      const theme = this.theme ? { [`-${this.theme}`]: true } : {
        '-primary': this.primary,
        '-positive': this.success,
        '-negative': this.error,
        '-support': this.info,
        '-alert': this.alert,
        '-complementary': this.complementary,
        '-canceled': this.canceled,
        '-alert-dark': this.alertDark,
      }

      return [ 'c-label',
        {
          ...theme,
          '-thin': this.thin,
          '-thick': this.thick,
          '-disabled': this.disabled || this.muted,
          '-fixed-width': !!this.cssWidth,
          '-alternative': this.alternative
        }
      ]
    },

    cssWidth () {
      return ({
        'number': `${this.width}px`,
        'string': this.width
      })?.[typeof this.width]
    },

    styles () {
      const isArrayBg = Array.isArray(this.backgroundColor)
        && !!this.backgroundColor.length
      const isStringBg = is(this.backgroundColor, 'String')
        && this.backgroundColor.length > 1

      const color = this.textColor
      const backgroundColor = isStringBg && this.backgroundColor
      const background = isArrayBg && [
        'linear-gradient(90deg, ',
        this.backgroundColor.join(', '),
        ')'
      ].join('')

      return {
        background,
        backgroundColor,
        color,
        '--width': this.cssWidth,
        '--custom-box-shadow': this.backgroundColor
          ? `0 4px 20px 0 ${HextoRGB(backgroundColor, 0.3)}`
          : 'unset'
      }
    }
  }
}
</script>

<style lang="scss">
.c-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 25px;

  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--title-font-family);
  color: color-var(text, base-30);

  border-radius: 5px;
  padding: 5px 25px 6px;

  background-color: color-var(text, base-05);
  background-repeat: no-repeat;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  box-shadow: var(--custom-box-shadow);

  &.-primary,
  &.-positive,
  &.-negative,
  &.-alert,
  &.-alert-dark,
  &.-support {
    color: white;
  }

  &.-primary {
    background: color-var(default, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(default, base-rgb), .01);
    color: rgba(color-var(default, base-rgb), 0.5);

    & > .icon {
      @include icon-color(rgba(color-var(default, base-rgb), 0.5));
    }

    &.-alternative {
      background: rgba(color-var(primary, base-rgb), .2);
      box-shadow: 0 4px 20px 0 rgba(color-var(primary, base-rgb), .1);
      color: color-var(primary);
    }
  }

  &.-positive {
    background: color-var(positive, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(positive, base-rgb), .01);
    color: rgba(color-var(positive, base-rgb), 1.0);

    & > .icon {
      @include icon-color(rgba(color-var(positive, base-rgb), 1.0));
    }

    &.-alternative {
      background: rgba(color-var(positive, base-rgb), .2);
      box-shadow: 0 4px 20px 0 rgba(color-var(positive, base-rgb), .1);
      color: color-var(positive);
    }
  }

  &.-negative {
    background: color-var(negative, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(negative, base-rgb), .01);
    color: rgba(color-var(negative, base-rgb), 0.6);

    & > .icon {
      @include icon-color(rgba(color-var(negative, base-rgb), 0.6));
    }

    &.-alternative {
      background: rgba(color-var(negative, base-rgb), .2);
      box-shadow: 0 4px 20px 0 rgba(color-var(negative, base-rgb), .1);
      color: color-var(negative);
    }
  }

  &.-alert {
    background: color-var(alert, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(alert, base-rgb), .01);
    color: rgba(color-var(alert, base-rgb), 1.0);

    & > .icon {
      @include icon-color(rgba(color-var(alert, base-rgb), 1.0));
    }

    &.-alternative {
      background: rgba(color-var(alert, base-rgb), .2);
      box-shadow: 0 4px 20px 0 rgba(color-var(alert, base-rgb), .1);
      color: color-var(alert);
    }
  }

  &.-alert-dark {
    background: rgba(color-var(alert, dark-rgb), 0.2);
    box-shadow: 0 4px 20px 0 rgba(color-var(alert, dark-rgb), .1);
    color: rgba(color-var(alert, dark-rgb), 0.8);

    & > .icon {
      @include icon-color(rgba(color-var(alert, dark-rgb), 0.8));
    }
  }

  &.-support {
    background: color-var(info, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(info, base-rgb), .01);
    color: rgba(color-var(info, base-rgb), 0.7);

    & > .icon {
      @include icon-color(rgba(color-var(info, base-rgb), 0.7));
    }

    &.-alternative {
      background: rgba(color-var(info, base-rgb), .2);
      box-shadow: 0 4px 20px 0 rgba(color-var(info, base-rgb), .1);
      color: color-var(info);
    }
  }

  &.-complementary {
    background: color-var(alert, extraLight);
    box-shadow: 0 2px 20px -2px rgba(color-var(alert, base-rgb), .01);
    color: rgba(color-var(alert, dark-rgb), 0.8);

    &.-alternative {
      background: rgba(color-var(alert, base-rgb), .2);
      box-shadow: 0 4px 20px 0 rgba(color-var(alert, base-rgb), .1);
      color: color-var(alert);
    }
  }

  &.-canceled {
    color: rgba(color-var(negative, base-rgb), .6);
  }

  &.-thin { padding: 5px 8px; }

  &.-thick { padding: 5px 60px; }

  &.-fixed-width {
    padding: 5px 0;

    width: var(--width);
    display: flex;
    justify-content: center;
  }

  &.-disabled {
    background: color-var(text, base-05);
    color: color-var(text, base-30);
    box-shadow: none;
    padding: 5px 15px;

    & > .icon {
      @include icon-color(color-var(text, base-30));
      margin-right: 5px;
    }
  }

  &.-disabled.-negative {
    background: rgba(color-var(negative, base-rgb), .2);
    color: color-var(negative);

    & > .icon {
      @include icon-color(rgba(color-var(negative, base-rgb), .5));
    }
  }
}
</style>
