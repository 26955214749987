<template>
  <div
    ref="progressBar"
    :class="classes"
    :style="styles"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <div class="progress-bar">
      <span
        :class="customBar"
        :style="{ width: percent < 0 ? 0 : `${percent}%` }"
      />

      <span
        v-if="average"
        ref="averageTarget"
        :class="[ 'average', { '-error': error } ]"
        :style="{ left: `${averagePercent}%` }"
        @mouseenter="showTooltip = true"
        @mouseleave="showTooltip = false"
      />
    </div>

    <span v-if="showPercent" class="percent">
      {{ percent }}%
    </span>

    <c-tooltip
      :show="showTooltipType"
      dark
      position="top"
      :element-spacing="5"
      :target="$refs[ (average && hasAverageTooltip) ? 'averageTarget' : 'progressBar' ]"
    >
      <slot />
    </c-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CIndicativeProgress',

  props: {
    /**
     * Enables the tooltip option for the average
     */
    hasAverageTooltip: Boolean,

    /**
     * Enables the tooltip option for bar
     */
    hasBarTooltip: Boolean,

    /**
    * Current value of progress
    */
    value: {
      type: [ Number, String ],
      default: 0
    },

    /**
    * Current value of progress
    */
    average: {
      type: [ Number, String ],
      default: 0
    },

    maxProgress: {
      type: [ Number, String ],
      default: 100
    },

    /**
    * Indicates whether the value should be on alongside
    */
    inline: {
      type: Boolean,
      default: false
    },

    showPercent: {
      type: Boolean,
      default: false
    },

    decimalPlaces: {
      type: Number,
      default: 0
    },

    /**
     * Uses error color for the background.
     */
    error: Boolean,

    /**
     * Uses alert color for the background.
     */
    alert: Boolean,

    /**
     * Uses info color for the background.
     */
    info: Boolean,

    /**
     * Uses positive color for the background.
     */
    positive: {
      type: Boolean,
      default: true
    },

    showInTooltip: Boolean,

    minWidth: {
      type: String,
      default: '0px'
    }
  },

  data: () => ({
    showTooltip: false
  }),

  computed: {
    classes () {
      return [ 'c-indicative-progress',
        {
          '-inline': this.inline,
          '-in-tooltip': this.showInTooltip
        }
      ]
    },

    styles () {
      return [
        {
          '--min-width': this.minWidth
        }
      ]
    },

    customBar () {
      return [ 'bar',
        {
          '-error': this.error,
          '-alert': this.alert,
          '-info': this.info,
          '-positive': this.positive
        }
      ]
    },

    percent () {
      return ((this.value / this.maxProgress) * 100).toFixed(this.decimalPlaces)
    },

    averagePercent () {
      return ((this.average / this.maxProgress) * 100).toFixed(this.decimalPlaces)
    },

    showTooltipType () {
      return (this.hasAverageTooltip || this.hasBarTooltip) && this.showTooltip
    }
  }
}
</script>

<style lang="scss">
.c-indicative-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: var(--min-width);

  & > .progress-bar {
    position: relative;
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: color-var(text, base-10);
    overflow: hidden;

    & > .bar {
      height: 100%;
      transition: width .3s ease-out;
      border-radius: 10px;
      display: block;

      &.-positive {
        background: color-var(positive);
        box-shadow: 0 0 15px -2px rgba(#37CFCC, .5);
      }

      &.-error {
        background: color-var(negative);
        box-shadow: 0 4px 20px 0 rgba(color-var(negative, base-rgb), .3);
      }
      &.-alert {
        background: color-var(alert);
        box-shadow: 0 4px 20px 0 rgba(color-var(alert, base-rgb), .3);
      }
      &.-info {
        background: color-var(info);
        box-shadow: 0 4px 20px 0 rgba(color-var(info, base-rgb), .3);
      }
    }

    & > .average {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: color-var(text, base-10);
      border-radius: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &.-error {
        background-color: color-var(text, base-20);
      }
    }
  }

  & > .percent {
    font-size: 12px;
    line-height: 1;
    color: color-var(text, base-80);
    align-self: flex-end;
    margin-top: 4px;
  }

  &.-inline {
    flex-direction: row;
    gap: 0;

    & > .progress-bar {
      width: calc(100% - 40px);
      flex-grow: 1;
      margin-right: 0;
    }

    & > .percent {
      margin: 0;
      margin-left: 10px;
      position: static;
      white-space: nowrap;
    }
  }

  &.-in-tooltip {
    background-color: rgb(230, 228, 228, 0.1);
  }
}
</style>
