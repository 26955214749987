<template>
  <apex-charts
    ref="apex"
    class="c-graph-line"
    type="area"
    :width="width"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
import { getScrollParent } from '@convenia/helpers'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'CGraphLine',

  components: {
    ApexCharts: VueApexCharts
  },

  props: {
    width: {
      type: Number,
      default: undefined
    },

    height: {
      type: Number,
      default: undefined
    },

    value: {
      type: Array,
      default: () => ([])
    },

    categories: {
      type: Array,
      default: () => ([])
    },

    tooltip: {
      type: Function,
      default: ({ name, data, dataPointIndex }) => `${name}: ${data[dataPointIndex]}`
    },

    stroke: {
      type: [ Object, String ],
      default: 'curve'
    },

    xOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
        offsetX: undefined,
        offsetY: undefined,
      })
    },

    yOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        width: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
        offsetX: undefined,
        offsetY: undefined,
      })
    },

    colors: {
      type: Array,
      default: () => ([ '#4BA9FF' ])
    },

    fillColors: {
      type: Array,
      default: undefined
    },

    markers: {
      type: Object,
      default: () => ({})
    }
  },

  watch: {
    value (value) {
      this.series = value
    },
  },

  computed: {
    options () {
      return {
        chart: {
          foreColor: 'rgba(18,30,72,0.8)',
          animations: { enabled: false, },
          zoom: { enabled: false },
          toolbar: { show: false },
          dropShadow: {
            enabled: true,
            color: this.colors,
            opacity: 0.2,
            top: 0,
            left: 0,
            blur: 5,
          },
          fontFamily: 'Nunito Sans',
          events: {
            dataPointSelection: (e, context, opts) => this.$emit('select', { e, context, opts })
          },
        },
        dataLabels: {
          enabled: false
        },
        states: {
          hover: {
            filter: { type: 'none', value: 0 }
          },
          active: {
            filter: { type: 'none', value: 0 }
          },
        },
        fill: {
          type: 'gradient',
          colors: this.fillColors,
          gradient: {
            inverseColors: true,
            gradientToColors: this.stroke.curve?.map(curve => curve === 'stepline' ? 'transparent' : undefined),
            opacityFrom: 0.4,
            opacityTo: 0.0,
            stops: [ 0, 100 ],
          }
        },
        colors: this.colors,
        stroke: {
          width: this.stroke.width || 3,
          curve: this.stroke.curve || 'smooth',
          dashArray: this.stroke.dashArray,
        },
        markers: {
          colors: 'white',
          strokeColors: this.colors,
          strokeWidth: 4,
          radius: 4,
          ...this.markers,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          showForSingleSeries: true,
          fontSize: '10px',
          fontWeight: 400,
          fontFamily: 'Ubuntu',
          markers: {
            size: 4,
          },
          itemMargin: {
            vertical: 5
          },
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          },
        },
        grid: {
          padding: { right: 20 }
        },
        tooltip: {
          enabled: true,
          shared: false,

          custom: ({ seriesIndex, dataPointIndex, w }) => {
            const { data, name } = w.globals.initialSeries[seriesIndex]
            const category = this.categories[dataPointIndex]
            const value = data[dataPointIndex]

            const leftSide = Math.floor(this.categories.length / 2) > dataPointIndex
            const tooltipOffset = leftSide ? 20 : 0

            const content = this.tooltip({ data, name, category, seriesIndex, dataPointIndex, w })

            if (!content)
              return null

            const scrollParent = getScrollParent(this.$el) || {}
            const scrollTop = (scrollParent.scrollTop || 0) + (value === 0 ? 20 : 0)
            const scrollLeft = (scrollParent.scrollLeft || 0)

            return `<div class="c-tooltip" style="transform: translate(calc(-${tooltipOffset}px - ${scrollLeft}px))">
            <div class="c-popover popover -dark" style="transform: translate(-50%, calc(-${scrollTop}px - 90%)); --left: 50%;">
              <div class="card" style="--tooltip-font-size:11px; --tooltip-max-width:200px; --tooltip-horizontal-padding:10px; text-align: left">
                ${content}
              </div>
            </div>
          </div>`
          }
        },
        xaxis: {
          categories: this.categories,
          min: this.xOptions.min,
          max: this.xOptions.max,
          tickAmount: this.xOptions.tickAmount,
          title: {
            text: this.xOptions.title, // Define title so it "ocuppies" space
            style: { color: 'transparent' }
          },
          labels: {
            rotate: 0,
            trim: this.xOptions.trimLabels,
            hideOverlappingLabels: false,
            formatter: this.xOptions.formatter || (value => `${value}`),
            offsetX: this.xOptions.offsetX || null,
            offsetY: this.xOptions.offsetY || null,
            style: {
              fontSize: '10px',
              fontWeight: 500,
              colors: 'rgba(55,61,63,0.5)'
            }
          },
        },
        yaxis: {
          min: this.yOptions.min,
          max: this.yOptions.max,
          tickAmount: this.yOptions.tickAmount,
          title: {
            style: { color: 'transparent' }
          },
          labels: {
            formatter: this.yOptions.formatter || (value => `${value}`),
            minWidth: this.yOptions.width || 70,
            maxWidth: this.yOptions.width || 70,
            offsetX: this.yOptions.offsetX || null,
            offsetY: this.yOptions.offsetY || null,
            style: {
              fontSize: '11px',
              fontWeight: 500,
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        }
      }
    }
  },

  data: (vm) => ({
    series: vm.value,
  })
}
</script>

<style lang="scss">
.c-graph-line {
  .apexcharts-xaxistooltip { display: none; }
  .apexcharts-legend {
    .apexcharts-legend-marker { margin-right: 5px; }
    .apexcharts-legend-text { text-transform: uppercase; }
    .apexcharts-legend-series { display: inline-flex !important; }
  }
  .c-tooltip > .popover::before {
    top: initial;
    bottom: -5px;
  }
}
</style>
