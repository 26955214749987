<template>
  <validation-observer ref="veeObserver" slim>
    <form
      class="c-form-builder"
      :class="{ '-show-overflow': showOverflow || adjustOverflow }"
      novalidate
      @submit.prevent="onSubmit"
    >
      <c-shadowed
        :shadow-color="shadowColor"
        :has-upper-shadow="!noUpperShadow"
        :has-bottom-shadow="!noBottomShadow"
      >
        <div ref="form-fields" class="form-fields">
          <template v-for="(fieldSchema, fieldName) in mutatedSchema">
            <div
              v-if="$slots[`${fieldName}-divider`]"
              :key="`${fieldName}-divider`"
              :class="[ 'divider', `${fieldName}-divider` ]"
            >
              <slot :name="`${fieldName}-divider`" />
            </div>

            <component
              :is="getFieldComponentType(fieldSchema.type)"
              ref="fields"
              :key="fieldName"
              :class="[ 'field', fieldSchema.type ]"
              :field-name="fieldName"
              :field-schema="fieldSchema"
              :form-schema="schema"
              :form-value="formData"
              :disabled="disabled"
              :field-options="getFieldOptions(fieldName, fieldSchema)"
              :fields-options="fieldsOptions"
              :label-left="getFieldLabelLeft(fieldSchema)"
              :value="formData[fieldName]"
              :has-validation="hasValidation"
              :errors="errors[fieldSchema.customValidationName || fieldName]"
              v-bind="$attrs"
              @pre-validate="onFieldPreValidate(fieldName, fieldSchema, $event)"
              @list:add="$emit(`list-add:${fieldName}`, $event)"
              @list:remove="$emit(`list-remove:${fieldName}`, $event)"
              @toggle-preview="onTogglePreview"
              v-on="getFieldHandlers(fieldName, fieldSchema)"
            >
              <template
                v-if="fieldSchema.type === 'slot'"
                v-slot="fieldProps"
              >
                <slot
                  :name="`${fieldName}-slot`"
                  v-bind="{
                    ...fieldProps,
                    fieldHandlers: getFieldHandlers(fieldName, fieldSchema)
                  }"
                  v-on="getFieldHandlers(fieldName, fieldSchema)"
                />
              </template>
            </component>
          </template>

          <slot />
        </div>

        <slot name="footer" />
      </c-shadowed>

      <div v-show="!noActions" class="actions">
        <slot name="actions">
          <c-button primary class="action">Salvar</c-button>
        </slot>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { getScrollParent, mapRequiredFields, is } from '@convenia/helpers'
import FormField from './fragments/FormField'
import FormFieldList from './fragments/FormFieldList'
import FormFieldRow from './fragments/FormFieldRow'
import SubmitFormat from './mixins/SubmitFormat'

const getDefault = type => {
  if (type === 'check') return false
  if (type === 'radio') return null
  const arrayTypes = [ 'file', 'multi-select', 'multi-input', 'multi-check' ]
  if (arrayTypes.includes(type)) return []
  return ''
}

const has = val => val !== null && val !== undefined

/**
 * TODO:
 * - Scroll to first error in cases of submission attemps with validation errors
 * - Declarative form syntax
 * - Polish event handlers
 * - Test integration with file upload components
 */

export default {
  name: 'CFormBuilder',

  mixins: [
    SubmitFormat({
      schemaRef: 'schema',
      root: true,
      childRefsName: 'fields'
    })
  ],

  components: {
    ValidationObserver,
    FormFieldList,
    FormFieldRow,
    FormField,
  },

  props: {
    /**
     * The model of the form, contains the initial values for each
     * respective field in the schema.
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * The schema of the value, contains all field definitions
     */
    schema: {
      type: Object,
      required: true
    },

    /**
     * An object containing the options lists for each field,
     * each property will be matched against the appropriate
     * 'optionsSrc' found in the schema of each field.
     */
    fieldsOptions: {
      type: Object,
      default: () => ({})
    },

    /**
     * Whether to remove the default action buttons from the template
     */
    noActions: Boolean,

    /**
     * Whether to disable the entire form
     */
    disabled: Boolean,

    /**
     * Whether to render the fields labels on the left
     * instead of rendering it on the top of the field
     */
    labelLeft: {
      type: Boolean,
      default: false
    },

    /**
     * Color of CShadowed
     */
    shadowColor: {
      type: String,
      default: '',
    },

    /**
     * No bottom shadow on CShadowed
     */
    noBottomShadow: Boolean,

    /**
     * No upper shadow on CShadowed
     */
    noUpperShadow: Boolean,

    /**
     * Forces all fields to be validated whenever one of the fields
     * is changed (after initial submit event)
     */
    revalidateOnInput: Boolean,

    /**
     * The default behavior of the CShadowed component is to hide
     * all the overflowing content so that the shadows dynamics
     * would work properly.
     *
     * This prop blatantly disables that.
     * Yes. Just like that!
     *
     * Kids these days...
     */
    showOverflow: Boolean,

    /**
     * Whether a form field, especially a select, should allow a null
     * value or just use the default value passed in the schema.
     * Very useful when you have a select field that has a value
     * set up in the schema but also has the nullable property set to true.
     */
    allowNull: {
      type: Boolean,
      default: false
    },

    /**
     * Another way to setup the form required fields.
     * Should be an array of string with the field keys.
     */
    requiredFields: {
      type: Array,
      default: () => ([])
    },

    /**
     * Is available, onRemoveFile will trigger the emit
     * instead of the standar method.
     */
    emitFileRemove: {
      type: Boolean,
      default: false
    },

    /**
     * List of fields errors
     */
    errors: {
      type: Object,
      default: () => ({})
    },

    /**
     * Show errors even if has not submitted
     */
    showErrorsWhenChanged: {
      type: Boolean,
    },
  },

  computed: {
    mutatedSchema () {
      const objectSchema = this.getFieldsObjectSchema(this.schema)
      return mapRequiredFields(objectSchema, this.requiredFields)
    },

    readData () {
      return { ...this.value, ...this.formData }
    }
  },

  data: () => ({
    formData: {},
    hasValidation: false,
    adjustOverflow: false
  }),

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: 'generateFormData'
    },

    schema: {
      handler: 'generateFormData'
    },

    errors: {
      handler (v) {
        this.triggerErrors()
        Object.keys(v).length && setTimeout(() => this.focusFirstError(), 100)
      }
    },
  },

  methods: {
    onTogglePreview (value) {
      if (!value)
        setTimeout(() => { this.adjustOverflow = value }, 400)
      else
        this.adjustOverflow = value
    },

    generateFormData () {
      const reducer = (acc, [ key, { value, type } ]) => {
        return {
          ...acc,
          [key]: (() => {
            if (has(this.value[key])) return this.value[key]
            if (this.allowNull && this.value[key] === null) return this.value[key]
            if (has(value) || (value === null && this.allowNull)) return value
            return getDefault(type)
          })()
        }
      }

      this.formData = Object.entries(this.mutatedSchema).reduce(reducer, {})
    },

    getFieldOptions (fieldName, fieldSchema) {
      return this.fieldsOptions[fieldSchema.optionsSrc || fieldName]
        || fieldSchema.options
        || []
    },

    getFieldsObjectSchema (schema) {
      return Object.entries(schema || {})
        .reduce((schema, [ fieldName, config ]) => {
          const objectConfig = is(config, 'Function')
            ? config(this)
            : config

          const validatedFieldSchema = objectConfig.type
            ? { [fieldName]: objectConfig }
            : {}

          return {
            ...schema,
            ...validatedFieldSchema
          }
        }, {})
    },

    // Event handle for generic form components, such as CInput
    // or anything that emits a @input event
    onInput (fieldName, fieldSchema, value) {
      if (value instanceof Event) return

      this.formData[fieldName] = value
    },

    // Event handler for form components that accept an array as value
    onAdd (fieldName, fieldSchema, value) {
      this.formData[fieldName] = [ ...(this.formData[fieldName] || []), value ]
    },

    // Event handler for form components that accept an array as value
    onRemove (fieldName, fieldSchema, index) {
      this.formData[fieldName].splice(index, 1)
    },

    // Event handler for file upload
    onAddFile (fieldName, field, files) {
      const value = Array.from(files).map(file => ({
        ...file,
        done: false,
        progress: 0,
        aborted: false,
        uploading: false,
        request: null
      }))

      if (!field.multiple)
        this.formData[fieldName] = value
      else {
        this.formData[fieldName] = [
          ...value,
          ...this.formData[fieldName]
        ]
      }
    },

    // Event handler for file upload
    onRemoveFile (fieldName, fieldSchema, file) {
      const index = this.formData[fieldName].indexOf(file)
      this.formData[fieldName].splice(index, 1)

      if (!this.emitFileRemove)
        return

      this.$emit('file:remove', file)
    },

    onFieldPreValidate (name, schema, event) {
      const { value, validation } = event
      const field = { name, schema, value }
      this.$emit('field-pre-validate', { validation, field })
    },

    emitFieldChange (fieldName, fieldSchema, value) {
      if (fieldSchema.onInput)
        fieldSchema.onInput(this.formData, value, this)

      if (this.revalidateOnInput && this.hasValidation)
        this.$nextTick(this.$refs.veeObserver.validate)

      this.$emit('input', this.formData)
      this.$emit(`input:${fieldName}`, value)
    },

    getFieldHandlers (fieldName, fieldSchema) {
      const { handlers: schemaHandlers } = fieldSchema

      const handlers = {
        add: this.onAdd,
        remove: this.onRemove,
        input: this.onInput,
        'file:add': this.onAddFile,
        'file:remove': this.onRemoveFile,
      }

      const formHandlers = Object.entries(handlers).reduce((acc, [ evName, evHandler ]) => ({
        ...acc,
        [evName]: value => evHandler(fieldName, fieldSchema, value)
          || this.emitFieldChange(fieldName, fieldSchema, value)
      }), {})

      return {
        ...formHandlers,
        ...(Object.entries(schemaHandlers || {}).reduce((acc, [ evName, evHandler ]) => ({
          ...acc,
          [evName]: evHandler
        }), {}))
      }
    },

    getFieldLabelLeft (fieldSchema) {
      if (fieldSchema.labelLeft === undefined)
        return this.labelLeft

      return fieldSchema.labelLeft
    },

    async onSubmit () {
      this.hasValidation = true
      const isValid = await this.$refs.veeObserver.validate()

      if (isValid) {
        const submitData = this.submitFormatEnabled
          ? this.getFormattedFields()
          : this.formData

        this.$emit('submit', submitData)
      } else {
        this.$emit('invalid-submit', this.formData)
        this.focusFirstError()
      }

      // In case this is being called through a ref, would
      // be useful to have this value returned
      return isValid
    },

    getFieldComponentType (type) {
      if (type === 'list') return 'form-field-list'

      if (type === 'row') return 'form-field-row'

      return 'form-field'
    },

    focusFirstError () {
      const form = (this.$refs || {})['form-fields']
      if (!form) return

      const errorFieldEls = form.getElementsByClassName('-has-errors')
      const errorFieldEl = (errorFieldEls || [])[0]
      if (!errorFieldEl) return

      const fieldEl = errorFieldEl.closest('.c-input-container') || errorFieldEl
      const parentEl = getScrollParent(fieldEl)
      const parentRect = parentEl.getBoundingClientRect()
      const fieldRect = fieldEl.getBoundingClientRect()

      const offsetTop = parentEl !== document.scrollingElement
        ? fieldRect.top - parentRect.top + parentEl.scrollTop
        : fieldRect.top - parentRect.top

      const top = offsetTop - (parentEl.clientHeight - fieldEl.clientHeight) / 2

      parentEl.scrollTo({ top, behavior: 'smooth' })
    },

    // These methods are suposed to be called from the parent component
    forceInput (fieldName, value) {
      const fieldSchema = this.schema[fieldName]

      this.emitFieldChange(fieldName, fieldSchema, value)
      this.onInput(fieldName, fieldSchema, value)
    },

    triggerFieldValidation (fieldName) {
      if (!fieldName || !this.$refs.fields) return

      const field = this.$refs.fields.find(f => ((f || {}).$attrs || {})['field-name'] === fieldName)

      if (field) field.validate()
    },

    resetValidations () {
      window.requestAnimationFrame(() => {
        this.$refs.veeObserver.reset()
      })
    },

    showValidations () {
      this.hasValidation = true
    },

    resetFields (...fields) {
      fields.forEach(field => {
        const fieldSchema = (this.mutatedSchema || {})[field]
        if (!fieldSchema) return

        const { value } = fieldSchema
        this.formData[field] = value === undefined
          ? null
          : value
      })
    },

    triggerErrors () {
      this.showErrorsWhenChanged && this.showValidations()
    }
  }
}
</script>

<style lang="scss">
.c-form-builder {
  display: flex;
  flex-direction: column;

  & > .c-shadowed > .wrapper > .form-fields {
    width: 100%;
    padding: 0 20px;

    & > .field[type="hidden"] { display: none }

    @include responsive (tablet, desktop) {
      & > .divider { margin-left: -20px; }
    }
  }

  & > .actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-shrink: 0;

    & > .action:not(:last-child) { margin-right: 20px; }
    @include responsive (tablet, desktop) { & > .action { width: 100%; max-width: 180px; } }
  }

  &.-show-overflow {
    & > .c-shadowed,
    & > .c-shadowed > .wrapper { overflow: visible }
  }
}
</style>
