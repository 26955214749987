<template>
  <span class="c-validation-message">
    <c-flag validation />

    <c-typo weight="semibold" color="negative">
      <slot slot="default" />
    </c-typo>
  </span>
</template>

<script>
export default { name: 'CValidationMessage' }
</script>

<style lang="scss">
.c-validation-message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
</style>
