<template>
  <div
    :class="[
      'c-input-raw',
      {
        '-jumbo': jumbo,
        '-has-icon': icon,
        '-not-empty': notEmpty,
        '-textarea': textArea,
        '-validation': error
      }
    ]"
    :style="style"
  >
    <c-icon
      v-if="icon"
      :icon="icon"
      :style="{ stroke: iconColor }"
      class="left-icon"
    />

    <c-input-abstract
      v-bind="$attrs"
      ref="input"
      :disabled="disabled"
      :text-area="textArea"
      :class="classes"
      v-on="$listeners"
      v-on:keyup="handleInput($event.target.value)"
    />

    <c-icon
      v-if="rightIcon && !(validation && jumbo)"
      :icon="rightIcon"
      :style="{ stroke: iconColor }"
      class="right-icon"
    />
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
/**
 * Our main input component.
 */
export default {
  name: 'CInputRaw',

  mixins: [ MediaQuery ],

  props: {

    /**
     * The icon to show on the left-side.
     */
    icon: {
      type: String,
      default: ''
    },

    /**
     * The icon to show in the right-side.
     */
    rightIcon: {
      type: String,
      default: ''
    },

    /**
     * The color of the icon(s).
     */
    iconColor: {
      type: String,
      default: null,
    },

    /**
     * Wheter the input has border-radius.
     */
    round: Boolean,

    /**
     * Wheter the input is opaque
     */
    opaque: Boolean,

    /**
     * Removes hover effect.
     */
    noHover: Boolean,

    /**
     * Disables the input.
     */
    disabled: Boolean,

    /**
     * Whether it is a textarea.
     */
    textArea: Boolean,

    /**
     * Jumbo style.
     */
    jumbo: Boolean,

    /**
     * Horizontal padding (in px)
     */
    horizontalPadding: {
      type: [ String, Number ],
      default: 20
    },

    error: Boolean
  },

  computed: {
    classes () {
      return [
        'input',
        {
          '-has-icon': this.icon,
          '-has-right-icon': this.rightIcon,
          '-round': this.round,
          '-opaque': this.opaque,
          '-no-hover': this.noHover,
          '-disabled': this.disabled,
          '-textarea': this.textArea,
          '-empty': !(this.$attrs.value || '').length,
          '-is-safari': this.isSafari
        }
      ]
    },

    style () {
      return {
        '--horizontal-padding': `${this.horizontalPadding}px`
      }
    }
  },

  data () {
    return { notEmpty: false }
  },

  methods: {
    handleInput (value) {
      this.notEmpty = !!value
    },

    focus () {
      try {
        this.$refs.input.$el.focus()
      } catch (e) { return e }
    }
  },

  mounted () {
    this.$nextTick(() => {
      const { input } = this.$refs
      if (input) this.handleInput(input.$el.value)
    })
  }
}
</script>

<style lang="scss">
$icon-position: 8px;

.c-input-raw {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .left-icon, & > .right-icon {
    box-sizing: content-box;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    @include icon-color(text, base-30);
    padding-right: 5px;
  }

  & > .right-icon { right: $icon-position; }
  & > .left-icon { left: $icon-position; }

  & > .input {
    $text-indent: var(--horizontal-padding);
    padding-right: var(--horizontal-padding);
    $text-indent-icon: 40px;
    outline: 0;
    font-size: 14px;
    border: var(--base-border);
    border-radius: 5px;
    color: color-var(text, base-80);
    transition: box-shadow .3s ease;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::placeholder {
      color: color-var(text, base-30);
    }

    &.-empty {
      @include truncate(100%);
    }

    &:focus:not(.-no-hover) {
      border-color: rgba(color-var(primary, base-rgb), .35);
      @include hover();
    }

    &.-textarea {
      padding: 10px;
      text-indent: initial !important;
      white-space: pre-wrap;
      overflow-y: auto;
    }

    &:not(.-textarea) { height: 40px; text-indent: $text-indent; }

    &.-has-icon { text-indent: $text-indent-icon; }
    // &.-has-icon.-disabled { text-indent: $text-indent-icon + #{var(--base-border-size)}; }
    &.-has-right-icon { padding-right: 40px; }
    &.-round { border-radius: 20px; border: var(--base-border); }
    &.-opaque { background-color: color-var(text, base-05) }
    &.-disabled {
      cursor: default;
      font-family: var(--base-font-family);
      border: 0;
      color: #40486D;
      opacity: 1;
      background-color: color-var(text, base-05);

      &.-textarea{ border: 1px solid transparent; }
    }

    &::-webkit-inner-spin-button,::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & > .validation { display: block; }
  & > .jumbo-validation { display: none; }

  &.-validation > .input,
  &.-validation > .input:focus:not(.-no-hover) {
    &:not(.-disabled) {
      border-color: rgba(color-var(negative, base-rgb), .35);
      @include hover(color-var(negative, base-rgb));
    }
  }

  &.-jumbo {
    & > .left-icon { left: 20px; }
    & > .right-icon { right: 20px; }

    & > .input {
      padding-top: 14px;
      text-indent: 0;
      padding-right: 20px;
      padding-left: 20px;

      &.-textarea { padding-top: 27px; }
      &:not(.-textarea) { height: 60px; }
      &::placeholder { color: transparent; }
    }

    & > .label {
      top: 50%;
      transform: translateY(-50%);
      padding: 0 20px;
      font-family: var(--base-font-family);
      font-size: 14px;
      color: color-var(text, base-30);
      pointer-events: none;
      transition: top .3s, font-size .3s, transform .3s;
    }

    &.-textarea > .label { top: 30px; }

    &.-has-icon {
      & > .label { left: 25px; }
      & > .input { padding-left: 44px; }
    }

    &:focus-within > .label,
    &.-not-empty > .label {
      top: 14px;
      transform: translateY(0);
      font-size: 11px;
    }

    &.c-input { margin-top: 0; }

    & > .validation { display: none; }
    & > .jumbo-validation { display: block; }

    &.-validation:not(.-disabled) {
      & > .input {
        border-color: rgba(color-var(negative, base-rgb), .2);
        background: color-var(negative);
        box-shadow: none;
        color: color-var(negative);
        padding-right: 50px;
      }
      & > .input:focus:not(.-no-hover) { @include hover(color-var(negative, base-rgb)); }
      & > .label { color: color-var(negative); }
      & > .left-icon,
      & > .right-icon { @include icon-color(negative); }
    }
  }

  @include xs-mobile {
    &.-jumbo {
      &:not(.-textarea) { height: 50px; }

      & > .label { padding: 0 15px; }

      &:focus-within > .label,
      &.-not-empty > .label { top: 6px; }

      & > .input {
        padding: {
          top: 10px;
          left: 15px;
          right: 15px;
        }
      }
    }
  }
}
</style>
