<template>
  <c-card
    no-border
    class="big-data-card"
  >
    <div class="header-info">
      <span class="header-title"> {{ title }} </span>

      <span v-if="infoHeader" class="optional-info">
        <c-icon
          v-if="hasIconInfoHeader"
          icon="interface-essential-receipt"
          class="icon"
          size="14"
        />

        <span class="text"> {{ infoHeader }} </span>
      </span>
    </div>

    <div class="body-info">
      <div class="body-info-container">
        <span v-if="infoBody">
          {{ infoBody }}
        </span>

        <slot v-else name="body" />
      </div>

      <c-flag
        v-if="isMobile"
        data-testid="teste"
        :icon="iconCard"
        :size="40"
        :success="success"
        :alert="alert"
        :error="error"
        :primary="primary"
      />
    </div>

    <div class="footer-info">
      <slot class="footer" name="footer" />

      <c-flag
        v-if="!isMobile"
        :icon="iconCard"
        :success="success"
        :error="error"
        :alert="alert"
        :primary="primary"
      />
    </div>
  </c-card>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CBigDataCard',

  mixins: [ MediaQuery ],

  props: {
    title: {
      type: String,
      required: true
    },

    iconCard: {
      type: String,
      required: true
    },

    hasIconInfoHeader: Boolean,

    success: {
      type: Boolean,
      default: false
    },

    alert: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    },

    primary: {
      type: Boolean,
      default: false
    },

    infoHeader: {
      type: [ String, Number ],
      required: true
    },

    infoBody: {
      type: String,
      required: true
    },
  }
}
</script>
<style lang="scss">
.big-data-card {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding: 15px;

  & > .header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    min-height: 19px;

    & > .header-title {
      font-size: 9px;
      @include typo(h5, base-50);
    }

    & > .optional-info {
      margin-right: 5px;
      display: flex;
      align-items: center;
      @include typo(body-2);

      & > .icon {
        margin-right: 6px;
        @include icon-color(text, base-20);
      }
    }
  }
  & > .body-info {
    @include typo(h1);

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 30px;

    & > .body-info-container {
      @include responsive(xs-mobile, mobile) {
        margin: -50px 0 0 0;
      }
    }

    & > .c-flag {
      margin-bottom: 15px;
      margin-left: 15px;
      @include responsive(xs-mobile, mobile) {
        margin-top: 26px;
      }
    }

    @include responsive(xs-mobile, mobile) {
      font-size: 32px;
    }
  }

  & > .footer-info {
    display: flex;
    align-items: flex-end;
    margin: -10px 0 20px 20px;
    @include responsive(tablet, desktop) {
      justify-content: flex-end;
    }
  }
  @include responsive(xs-mobile, mobile) {
    width: 100%;
    height: 120px;
    min-width: 340px;
    border-radius: 5px;
  }
}

</style>
