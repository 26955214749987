<template>
  <div class="c-info-row-item" v-bind="computedBind">
    <div class="icon">
      <c-icon :icon="icon" size="20" />
    </div>

    <div class="label">
      <c-typo opacity="base-50" weight="medium" type="h4">{{ label }}</c-typo>
    </div>

    <div
      ref="content"
      class="content"
    >
      <c-typo
        v-tooltip.dark="{ value, hide: !isTruncated }"
        opacity="base-80"
        weight="semibold"
        type="body-1"
      >
        {{ value }}
      </c-typo>

      <slot />
    </div>
  </div>
</template>

<script>
import CIcon from '@convenia/components/CIcon'
import CTypo from '@convenia/components/CTypo'

export default {
  name: 'CInfoRowItem',

  components: {
    CIcon,
    CTypo,
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    /**
     * The name of the icon to be displayed.
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * The variant of the icon.
     */
    iconVariant: {
      type: String,
      default: 'text',
      validator: (value) => [ 'text', 'primary', 'positive', 'alert', 'alert-dark', 'negative', 'support' ].includes(value),
    },
    /**
     * The text for the label.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Determines if the layout should be a grid.
     */
    grid: {
      type: Boolean,
      default: false,
    },
    /**
     * The width of the component, specified in pixels or percentage.
     * Example: '100px' or '50%'.
     */
    width: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    isTruncated: false,
  }),

  computed: {
    computedClasses () {
      return {
        '-grid': this.grid,
        [`-icon-variant-${this.iconVariant}`]: this.iconVariant,
        '--custom-width': !!this.width
      }
    },

    computedStyles () {
      return {
        ...(this.width && { '--width': this.width })
      }
    },

    computedBind () {
      return {
        class: this.computedClasses,
        style: this.computedStyles
      }
    }
  },

  methods: {
    checkTruncation () {
      const element = this.$refs.content
      if (element) {
        this.isTruncated = element.scrollWidth > element.clientWidth
      }
    },
  },

  mounted () {
    this.checkTruncation()
    window.addEventListener('resize', this.checkTruncation())
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.checkTruncation())
  },
}
</script>

<style lang="scss">
.c-info-row-item {
  display: flex;
  width: 100%;
  gap: 10px;

  @include responsive(tablet, desktop) {
    padding: 20px 10px;
    min-width: 0;
  }

  &.--custom-width {
    & > .content {
      display: flex;
      align-items: center;
      width: var(--width);
    }
  }

  & > .content {
    min-width: 0;
    @include truncate(100%);

    @include responsive(xs-mobile, mobile) {
      display: flex;
      justify-content: flex-end;
    }
  }

  @include responsive(xs-mobile, mobile) {
    align-items: center;
    &:first-child {
      padding-top: 5px;
    }

    &:last-child {
      padding-bottom: 5px;
    }

    &.-grid {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-template-areas:
        "icon label"
        "content content";

      & > .icon {
        grid-area: icon;
      }

      & > .label {
        grid-area: label;
      }

      & > .content {
        grid-area: content;
        justify-content: start;
        width: 100%;
      }
    }
  }

  @each $name, $colors in $color-pallete {
    @if $name != "text" and $name != "info" {
      &.-icon-variant-#{$name} > .icon > .c-icon {
        @include icon-color(#{map-get($colors, base)});
      }
      &.-icon-variant-#{$name}-dark > .icon > .c-icon {
        @include icon-color(#{map-get($colors, dark)});
      }
    } @else if $name == "info" {
      &.-icon-variant-support > .icon > .c-icon {
        @include icon-color(#{map-get($colors, base)});
      }
    } @else {
      & > .icon > .c-icon {
        @include icon-color(color-var(text, base-30));
      }
    }
  }
}
</style>
