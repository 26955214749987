<template>
  <div class="c-info-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CInfoRow',
}
</script>

<style lang="scss">
.c-info-row {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  width: 100%;
  background-image: url("/img/pattern-pdi-card.svg");
  background-size: cover;
  background-position: center;
  border-radius: 5px;

  @include responsive(tablet, desktop) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    min-height: 60px;
    max-width: 100%;
  }
}
</style>
