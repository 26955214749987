<template>
  <div
    :class="classes"
    :style="{
      '--icon-size': icon ? iconSize + 'px' : '0px',
      '--icon-gap': icon ? iconGap + 'px' : '0px'
    }"
  >
    <c-icon
      v-if="icon"
      class="icon"
      :icon="icon"
      :size="iconSize"
    />

    <span v-tooltip.ellipsis.dark class="text">
      <slot />
    </span>

    <div
      v-if="removable"
      class="remove"
      @click="$emit('remove')"
    >
      <c-icon
        class="icon"
        icon="delete-disabled"
        size="11"
      />
    </div>
  </div>
</template>

<script>
/**
 * A simple information tag with an icon
 */
export default {
  name: 'CInfoTag',

  props: {
    /**
     * The icon to show in the left side of the tag. Optional.
     */
    icon: {
      type: String,
      default: ''
    },

    /**
     * The size of the icon.
     */
    iconSize: {
      type: [ String, Number ],
      default: '20'
    },

    /**
     * Adds a X icon that makes the tag removable
     */
    removable: {
      type: Boolean,
      default: false,
    },

    /** The space between the icon and the text.
     */
    iconGap: {
      type: [ String, Number ],
      default: '8'
    },
  },

  computed: {
    classes () {
      return [ 'c-info-tag', { '-removable': this.removable } ]
    },
  },
}
</script>

<style lang="scss">
.c-info-tag {
  padding: 6px;
  padding-right: 14px;
  height: 25px;
  border-radius: 12.5px;
  background-color: color-var(text, base-05);
  display: inline-flex;
  align-items: center;

  & > .icon {
    @include icon-color(text, base-30);
    margin-right: var(--icon-gap);
  }

  & > .text {
    @include typo(h5, base-50);
    @include truncate(100%);
    line-height: 11px;
    width: calc(100% - var(--icon-size) - var(--icon-gap));
  }

  & > .icon + .text { display: inline-block; }

  & > .remove {
    margin-left: 10px;
    cursor: pointer;

    & > .icon {
      @include icon-color(text, base-50);
    }
  }

  &.-removable { padding-right: 10px; }
}
</style>
